import {
  ACTIVE_LANGUAGES,
  DASHBOARD_STATITICS,
  GET_SETTINGS,
  ALL_COURSE,
  GET_ALL_MODULES,
  GET_MODULE_ASSETS_BY_ID,
  GET_MODULE_CHAPTERS_BY_ID,
  GET_CHAPTER_BY_ID,
  GET_ALL_COURSE_QUIZ,
  GET_ALL_CHAPTER_QUIZ,
  GET_ALL_EVENTS,
  GET_EVENT_BY_ID,
  GET_ALL_EVENT_PRIZE,
  GET_ALL_ADVERTISEMENTS,
  ENROLLED_USERS,
  GET_ALL_USERS_FEEDBACKS,
  GET_EVENT_PARTICIPANT_HISTORY,
  GET_CONTACT_REQUEST,
} from "constants/apiRequest";
import axios from "axios";
import axiosConfig from "config/axios";
import { redirect } from "react-router";

const fetcher = (url) => axios.get(url).then(({ data: { data } }) => data);

const getActiveLanguages = () => fetcher(ACTIVE_LANGUAGES);
const getAllCourse = () => fetcher(ALL_COURSE);

/** Loader for dashboard route */
export const dashboardLoader = async () => {
  axiosConfig.setAxiosBaseUrl();
  axiosConfig.setAxiosAuthorizationHeader(localStorage.getItem("token"));

  const settings = await axios.get(GET_SETTINGS);

  if (settings.status === 403) {
    return redirect("/");
  }

  const course = await getAllCourse();

  return { settings: settings.data.data, course };
};

/** Dashboard Page Loader */
export const dashboardStaticsLoader = async () => {
  const statitics = await fetcher(DASHBOARD_STATITICS);
  return {
    statitics,
  };
};

/** loader for course route */
export const courseLoader = async () => {
  const activeLanguages = await getActiveLanguages();
  return {
    activeLanguages,
  };
};

/** Loader for course quiz route */
export const courseQuizLoader = async ({ params: { courseId, quizPage } }) => {
  const activeLanguages = await getActiveLanguages();
  const quizDetails = await fetcher(GET_ALL_COURSE_QUIZ(courseId, quizPage));

  if (quizPage) {
    const {
      quiz: { data },
    } = quizDetails;

    if (quizPage > 1 && data.length <= 0) {
      return redirect(`/dashboard/courses/${courseId}/quiz/${quizPage - 1}`);
    }
  }

  return {
    activeLanguages,
    quizDetails,
  };
};

/** Loader for course module route */
export const moduleLoader = async ({ params: { page } }) => {
  const activeLanguages = await getActiveLanguages();
  const modules = await fetcher(GET_ALL_MODULES(page));

  if (page) {
    const { data } = modules;
    if (page > 1 && data.length <= 0) {
      return redirect(`/dashboard/modules/${page - 1}`);
    }
  }

  return {
    modules,
    activeLanguages,
  };
};

/** Loader for module asset route */
export const moduleAssetLoader = async ({ params: { moduleId } }) => {
  const activeLanguages = await getActiveLanguages();
  const moduleAssets = await fetcher(GET_MODULE_ASSETS_BY_ID(moduleId));

  return { moduleAssets, activeLanguages };
};

/** Loader for module chapter route */
export const moduleChapterLoader = async ({
  params: { moduleId, chapterPage },
}) => {
  const activeLanguages = await getActiveLanguages();
  const moduleChapters = await fetcher(
    GET_MODULE_CHAPTERS_BY_ID(moduleId, chapterPage)
  );

  if (chapterPage) {
    const {
      chapters: { data },
      module: { id },
    } = moduleChapters;
    if (chapterPage > 1 && data.length <= 0) {
      return redirect(`/dashboard/modules/${id}/chapters/${chapterPage - 1}`);
    }
  }

  return { moduleChapters, activeLanguages };
};

/** Loader for module chapter quiz route */
export const moduleChapterQuizLoader = async ({
  params: { chapterId, quizPage },
}) => {
  const activeLanguages = await getActiveLanguages();
  const quizDetails = await fetcher(GET_ALL_CHAPTER_QUIZ(chapterId, quizPage));

  if (quizPage) {
    const {
      parent: { courseModuleId },
      quiz: { data },
    } = quizDetails;

    if (quizPage > 1 && data.length <= 0) {
      return redirect(
        `/dashboard/modules/${courseModuleId}/chapters/${chapterId}/quiz/${
          quizPage - 1
        }`
      );
    }
  }
  return {
    activeLanguages,
    quizDetails,
  };
};

/** loader for module chapter asset route */
export const moduleChapterAssetLoader = async ({
  params: { moduleId, chapterId },
}) => {
  const activeLanguages = await getActiveLanguages();
  const chapter = await fetcher(GET_CHAPTER_BY_ID(moduleId, chapterId));

  return { chapter, activeLanguages };
};

/** Loader for event listing route */
export const eventListLoader = async () => {
  const events = await fetcher(GET_ALL_EVENTS);

  return { events };
};

/** Loader for create event route */
export const createEventLoader = async () => {
  const activeLanguages = await getActiveLanguages();
  const event = null;
  return { activeLanguages, event };
};

/** Loader for update event route */
export const updateEventLoader = async ({ params: { eventId } }) => {
  const activeLanguages = await getActiveLanguages();
  const event = await fetcher(GET_EVENT_BY_ID(eventId));
  return { activeLanguages, event };
};

/** Loader for event prize route */
export const eventPrizeLoader = async ({ params: { eventId, page } }) => {
  const activeLanguages = await getActiveLanguages();
  const eventPrizes = await fetcher(GET_ALL_EVENT_PRIZE(eventId, page));

  if (page) {
    const { data } = eventPrizes;
    if (page > 1 && data.length <= 0) {
      return redirect(`/dashboard/events/${eventId}/prizes/${page - 1}`);
    }
  }

  return {
    activeLanguages,
    eventPrizes,
  };
};

/** Loader for event participant history */
export const eventParticipantHistoryLoader = async ({
  params: { eventId, page },
}) => {
  const eventParticiapntDetails = await fetcher(
    GET_EVENT_PARTICIPANT_HISTORY(eventId, page)
  );
  return {
    eventParticiapntDetails,
  };
};

/** Loader for advertisements route */
export const advertisementLoader = async ({ params: { page } }) => {
  const activeLanguages = await getActiveLanguages();
  const advertisements = await fetcher(GET_ALL_ADVERTISEMENTS(page));

  if (page) {
    const { data } = advertisements;
    if (page > 1 && data.length <= 0) {
      return redirect(`/dashboard/advertisements/${page - 1}`);
    }
  }

  return {
    activeLanguages,
    advertisements,
  };
};

/** Loader for enrolled users routes */
export const enrolledUsersLoader = async ({ params: { page } }) => {
  const enrolledUsers = await fetcher(ENROLLED_USERS(page));
  return {
    enrolledUsers,
  };
};

/** Loader for users feedback route */
export const usersFeedbackLoader = async ({ params: { page } }) => {
  const usersFeedback = await fetcher(GET_ALL_USERS_FEEDBACKS(page));
  return {
    usersFeedback,
  };
};

/** Loader for contact request */
export const contactRequestLoader = async () => {
  const contactRequests = await fetcher(GET_CONTACT_REQUEST);

  console.log({
    contactRequests,
  });

  return {
    contactRequests,
  };
};
