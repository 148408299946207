/**
  This file is used for controlling the states of the App
*/
import { createContext, useContext, useReducer, useMemo } from "react";

// import prop-types for type checking
import { node } from "prop-types";

// creating App Context
const AppContext = createContext();

// setting AppContext Display Name
AppContext.displayName = "KSTAppContext";

// Kst App Reducer Function
function appReducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV":
      return { ...state, miniSideNav: action.value };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

// Kst App Controller Provider
function KstAppControllerProvider({ children }) {
  const initialState = {
    miniSideNav: false,
  };

  const [controller, dispatch] = useReducer(appReducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

KstAppControllerProvider.propTypes = {
  children: node.isRequired,
};

// Custom hooks for using Course Controller
function useKstAppController() {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      "useKstAppController should be used inside the KstAppControllerProvider."
    );
  }
  return context;
}

// Context module functions
const setMiniSidenav = (dispatch, value) =>
  dispatch({ type: "MINI_SIDENAV", value });

export { KstAppControllerProvider, useKstAppController, setMiniSidenav };
