import { func, node, string, bool } from "prop-types";
import { Edit, Delete } from "@mui/icons-material";

import AppCard from "components/core/AppCard";
import AppBox from "components/core/AppBox";
import AppTypography from "components/core/AppTypography";

import IconWrapper from "./IconWrapper";

function ListCard({
  title,
  handleEdit,
  handleDelete,
  isDelete,
  children,
  ...rest
}) {
  return (
    <AppCard {...rest}>
      {/* App Card Header Section */}
      <AppBox
        mb={1}
        py={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* App card Edit Delete Actions */}
        <AppBox display="flex" justifyContent="center" gap={1}>
          <IconWrapper
            tooltipTitle="Edit"
            handleAction={handleEdit}
            color="primary"
            placement="left"
          >
            <Edit fontSize="small" />
          </IconWrapper>
          {isDelete ? (
            <IconWrapper
              tooltipTitle="Delete"
              handleAction={handleDelete}
              color="primary"
              placement="bottom"
            >
              <Delete fontSize="small" />
            </IconWrapper>
          ) : null}
        </AppBox>
      </AppBox>

      {/* App Card Title */}
      <AppTypography variant="h6" color="dark" fontWeight="bold">
        {title}
      </AppTypography>

      {/* App Card childrens */}
      {children}
    </AppCard>
  );
}

ListCard.defaultProps = {
  isDelete: true,
  handleDelete: () => {},
};

ListCard.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  handleEdit: func.isRequired,
  handleDelete: func,
  isDelete: bool,
};

export default ListCard;
