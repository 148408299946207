// importing prop types for type checking
import { number, node } from "prop-types";

// importing @core component
import AppBox from "components/core/AppBox";

function TabPanel({ value, index, children }) {
  return (
    <div hidden={value !== index} role="tabpanel">
      {value === index && (
        <AppBox width="100%" mt={2}>
          {children}
        </AppBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  value: number.isRequired,
  index: number.isRequired,
  children: node.isRequired,
};

export default TabPanel;
