import { node } from "prop-types";
import { Grid } from "@mui/material";

function Container({ children }) {
  return (
    <Grid container spacing={3} mt={2} mb={2}>
      {children}
    </Grid>
  );
}

Container.propTypes = {
  children: node.isRequired,
};

export default Container;
