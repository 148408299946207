import * as yup from "yup";

/**
 * Application login validation schema
 */
const loginValidationSchema = yup.object({
  email: yup
    .string(`Enter admin email`)
    .email(`Email is not valid`)
    .required("Email is required"),
  password: yup
    .string("Enter admin password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

/**
 * Course Validation Schema
 */
const courseValidationSchema = yup.object({
  language: yup.string().required("Language is required"),
  title: yup.string().required("Title is required"),
  totalModules: yup
    .number()
    .min(1, "Total Modules must be greator than or equal to one")
    .max(20, "Total Modules must be less than or equal to twenty")
    .required("Total modules is required"),
});

/**
 * Course Module validation schema
 */
const courseModuleValidationSchema = yup.object({
  language: yup.string().required("Language is required"),
  courseId: yup.number().required("Course is required"),
  title: yup.string().required("Module name is required"),
});

/**
 * Course Module Asset Validation Schema
 */
const courseModuleAssetValidationSchema = yup.object().shape(
  {
    assetId: yup.string().nullable(),
    language: yup.string().required("Language is required"),
    type: yup.string().required("Asset type is required"),
    title: yup.string().required("Asset title is required"),
    linkUrl: yup.string().when("type", {
      is: "link",
      then: yup.string().url().required("Link url is required"),
    }),
    assetPdf: yup.mixed().when(["type"], {
      is: (type) => type === "pdf",
      then: yup
        .mixed()
        .required("Pdf file is required, Maximum File upload Size is 1MB"),
    }),
  },
  [["type"]]
);

/**
 * Chapter validation Schema
 */
const chapterValidationSchema = yup.object({
  id: yup.string().nullable(),
  language: yup.string().required("Language is required"),
  title: yup.string().required("Chapter name is required"),
  description: yup.string().required("Chapter description is required"),
  introHeading: yup.string().required("Intro heading is required"),
  posterImage: yup.mixed().required("Poster image is required"),
});

/**
 * Chapter Video Schema
 */
const chapterVideoSchema = yup.object({
  video_file: yup.mixed().required("Video File is required"),
});

/**
 * Chapter Video Subtitle Schema
 */
const chapterVideoSubtitleSchema = yup.object({
  language: yup.string().required("Language is required"),
  subtitle: yup.mixed().required("Subtitle file is required"),
});

/**
 * Chapter Asset video quiz validation
 * Schema
 */
const chapterVideoQuizSchema = yup.object({
  id: yup.string().nullable(),
  language: yup.string().required("Language is required"),
  question: yup.string().required("Question is required"),
  duration: yup.number().required("Quiz duration is required").moreThan(0),
  optionAnswer: yup.string().required("Please choose an quiz answer"),
});

/**
 * Common Quiz Validation Schema
 */
const quizValidationSchema = yup.object({
  id: yup.string().nullable(),
  language: yup.string().required("Language is required"),
  question: yup.string().required("Question is required"),
  options: yup
    .object({
      option_1: yup.string().required("Option 1 is required"),
      option_2: yup.string().required("Option 2 is required"),
      option_3: yup.string().required("Option 3 is required"),
      option_4: yup.string().required("Option 4 is required"),
    })
    .test(
      "is_options_are_same",
      () => `Some of the option values are same`,
      (item) => {
        const objKeys = Object.keys(item);
        let i = 0;
        let j = 1;
        let sameTypeFound = false;

        while (i < objKeys.length - 1) {
          if (item[objKeys[i]] === item[objKeys[j]]) {
            sameTypeFound = true;
            break;
          } else j += 1;

          if (j === objKeys.length) {
            i += 1;
            j = i + 1;
          }
        }
        return !sameTypeFound;
      }
    ),
  optionAnswer: yup
    .string()
    .when(
      [
        "options.option_1",
        "options.option_2",
        "options.option_3",
        "options.option_4",
      ],
      {
        is: (o1, o2, o3, o4) => o1 && o2 && o3 && o4,
        then: yup.string().required("Please choose a quiz answer"),
      }
    ),
});

/**
 * Event Validation Schema
 */

const tomorrow = (date = null) => {
  const today = date ? new Date(date) : new Date();
  today.setDate(today.getDate() + 1);
  return today.toISOString().split("T")[0];
};

const eventValidationSchema = yup.object({
  id: yup.string().nullable(),
  language: yup.string().required("Language is required"),
  eventTypeId: yup.number().required("Event type is required"),
  courseId: yup.number().required("Course is required"),
  title: yup.string().max(25).required("Title is required"),
  description: yup
    .string()
    .max(200, "Description must be at most 200 characters")
    .required("Description is requied"),
  startDate: yup
    .date()
    .when("id", {
      is: (id) => id === "",
      then: yup.date().min(tomorrow(), "Date must be future date"),
    })
    .required("Start Date is required"),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date can't be before start date")
    .required("End date is required"),
});

/**
 * Event Prize Validation Schema
 */
const eventPrizeValidationSchema = yup.object({
  id: yup.string().nullable(),
  language: yup.string().required("Language is required"),
  title: yup.string().required("Prize title is required"),
  prize_image: yup.mixed().required("Prize image is required"),
});

/**
 * Advertisement Validation Schema
 */
const advertisementValidationSchema = yup.object({
  id: yup.string().nullable(),
  language: yup.string().required("Language is required"),
  type: yup.string().required("Type is required"),
  title: yup.string().required("Title is required"),
  resourceUrl: yup
    .string()
    .url("Provided url is invalid")
    .required("Advertisement link url is required"),
  advertisement_file: yup
    .mixed()
    .required("Advertisement background is required"),
});

/**
 * Course Quiz Settings Validation Schema
 */
const courseQuizSettingsValidationSchema = yup.object({
  totalCourseQuiz: yup
    .number()
    .required("Total Course Quiz is required")
    .min(10)
    .max(25),
});

/**
 * Chapter Quiz Settings Validation Schema
 */
const chapterQuizSettingsValidationSchema = yup.object({
  totalChapterQuiz: yup
    .number()
    .required("Total Chapter Quiz is required")
    .min(5)
    .max(10),
});

/**
 * All schema named export
 */
export {
  loginValidationSchema,
  courseValidationSchema,
  courseModuleValidationSchema,
  courseModuleAssetValidationSchema,
  chapterValidationSchema,
  chapterVideoSchema,
  chapterVideoSubtitleSchema,
  chapterVideoQuizSchema,
  quizValidationSchema,
  eventValidationSchema,
  eventPrizeValidationSchema,
  advertisementValidationSchema,
  courseQuizSettingsValidationSchema,
  chapterQuizSettingsValidationSchema,
};
