import { node } from "prop-types";
// import { Card } from "@mui/material";

import AppBox from "components/core/AppBox";

function FormCard({ children }) {
  return (
    <AppBox
      p={2}
      m={2}
      display="flex"
      justifyContent="center"
      variant="contained"
    >
      {children}
    </AppBox>
  );
}

FormCard.propTypes = {
  children: node.isRequired,
};

export default FormCard;
