// prop-types is a library for typechecking of props.
import { oneOf, string, node } from "prop-types";

// importing Route change Nprogress hook
import useRouteProgress from "hooks/useRouteProgress";

// Material Dashboard 2 React components
import AppBox from "components/core/AppBox";

function PageLayout({ background, backgroundImage, type, children }) {
  useRouteProgress();

  return (
    <AppBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {type === "background" ? (
        <AppBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              backgroundImage &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {children}
        </AppBox>
      ) : (
        children
      )}
    </AppBox>
  );
}

// Setting default values for the props for PageLayout
PageLayout.defaultProps = {
  background: "default",
  backgroundImage: "",
  type: "standard",
};

// Typechecking props for the PageLayout
PageLayout.propTypes = {
  type: oneOf(["background", "standard"]),
  background: oneOf(["white", "light", "default"]),
  backgroundImage: string,
  children: node.isRequired,
};

export default PageLayout;
