import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const exportToCsv = (csvData, fileName, headerCols) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  XLSX.utils.sheet_add_aoa(ws, [headerCols], { origin: "A1" });

  const wsCols = Object.keys(csvData[0]).map(() => ({ wch: 20 }));

  ws["!cols"] = wsCols;

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const convertSecondsToMinuteSecondFormat = (givenSeconds) => {
  const dateObj = new Date(givenSeconds * 1000);
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

export const randomRgba = () => {
  const o = Math.round;
  const r = Math.random;
  const s = 255;

  return `rgba(${o(r() * s)}, ${o(r() * s)}, ${o(r() * s)}, ${r().toFixed(1)})`;
};

export const getCourseOptions = (courses) =>
  courses.map((item) => ({
    id: item.id,
    label: item.title.languages.en,
    value: item.id,
  }));
