// importing prop-types for type checking
import { func, string } from "prop-types";

// importing @mui components
import { Icon } from "@mui/material";

// importing @app core components
import AppButton from "components/core/AppButton";

function CreateButton({ title, handleOpen }) {
  return (
    <AppButton variant="gradient" color="dark" onClick={() => handleOpen()}>
      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
      &nbsp; {title}
    </AppButton>
  );
}

CreateButton.propTypes = {
  title: string.isRequired,
  handleOpen: func.isRequired,
};

export default CreateButton;
