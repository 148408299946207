import { useSnackbar } from "notistack";

// react router dom libraries
import { useNavigate, useLocation } from "react-router-dom";
import { useCurrentLocationName } from "hooks/useCurrentLocationName";
import useToggle from "hooks/useToggle";

// importing axios library
import axios from "axios";

// @material-ui core components
import { AppBar, Toolbar, IconButton, Icon } from "@mui/material";

// Material Ui dashboard components
import { AppBox, AppDialogue, AppTypography } from "components/core";

// TopNavbar Styles
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "includes/TopNavBar/styles";

// importing request api url
import { LOGOUT } from "constants/apiRequest";

// importing appContext
import { useKstAppController, setMiniSidenav } from "context/app";

function TopNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationName = useCurrentLocationName(location);
  const [controller, dispatch] = useKstAppController();
  const { miniSideNav } = controller;

  const [toggle, handleToggle] = useToggle();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    await axios.put(LOGOUT).then((response) => {
      const { message } = response.data;
      localStorage.removeItem("token");
      enqueueSnackbar(message, {
        variant: "success",
        autoHideDuration: 2000,
      });
      navigate("/");
    });
  };

  return (
    <>
      <AppBar position="sticky" color="inherit" sx={(theme) => navbar(theme)}>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <AppBox
            color="inherit"
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme)}
          >
            <AppTypography
              fontWeight="bold"
              textTransform="capitalize"
              variant="h6"
              color="dark"
              noWrap
            >
              {locationName}
            </AppTypography>
          </AppBox>
          <AppBox sx={(theme) => navbarRow(theme)}>
            <AppBox color="inherit">
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={() => setMiniSidenav(dispatch, !miniSideNav)}
              >
                <Icon
                  sx={({ palette: { dark } }) => ({
                    color: dark.main,
                  })}
                  fontSize="medium"
                >
                  {miniSideNav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                sx={navbarIconButton}
                size="small"
                onClick={() => handleToggle()}
              >
                <Icon
                  sx={({ palette: { dark } }) => ({
                    color: dark.main,
                  })}
                >
                  logout
                </Icon>
              </IconButton>
            </AppBox>
          </AppBox>
        </Toolbar>
      </AppBar>
      {toggle && (
        <AppDialogue
          open={toggle}
          dialogueTitle="Logout"
          dialogueDescription="Are you sure to logout?"
          confirmBtnTitle="Logout"
          confirmBtnAction={handleLogout}
          handleClose={handleToggle}
        />
      )}
    </>
  );
}

export default TopNavBar;
