// importing types for type checking
import { string, func, bool, oneOf } from "prop-types";

// importing @mui components
import { CircularProgress } from "@mui/material";

// importing @app components
import AppButton from "components/core/AppButton";
import AppBox from "components/core/AppBox";

function FormAction({
  type,
  isBack,
  disabled,
  isSubmitting,
  btnType,
  handleClose,
  submitBtn,
}) {
  return (
    <AppBox
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      gap={2}
      width="100%"
    >
      {submitBtn && (
        <AppButton
          size="medium"
          variant="gradient"
          disabled={isSubmitting || disabled}
          color="dark"
          type="submit"
        >
          {isSubmitting && (
            <>
              <CircularProgress size={18} color="inherit" />
              &nbsp;
            </>
          )}

          {type}
        </AppButton>
      )}
      {isBack ? (
        <AppButton
          size="medium"
          variant="gradient"
          disabled={isSubmitting && btnType === "default"}
          color="error"
          onClick={() => handleClose()}
        >
          Cancel
        </AppButton>
      ) : null}
    </AppBox>
  );
}

FormAction.defaultProps = {
  submitBtn: true,
  isSubmitting: false,
  isBack: false,
  disabled: false,
  btnType: "default",
  handleClose: () => {},
};

FormAction.propTypes = {
  type: string.isRequired,
  handleClose: func,
  isSubmitting: bool,
  disabled: bool,
  isBack: bool,
  submitBtn: bool,
  btnType: oneOf(["default", "video"]),
};

export default FormAction;
