import { useLoaderData } from "react-router";

// @mui components
import { Grid } from "@mui/material";

// material dashboard core components
import AppBox from "components/core/AppBox";

// Dasboard Components
import ComplexStatisticsCard from "./shared/ComplexStatiticsCard";
import RegistrationStatiticsChart from "./shared/RegistrationStatiticsChart";
import CountryStatiticsChart from "./shared/CountryStatiticsChart";

function Dashboard() {
  const {
    statitics: {
      availableCourses,
      conductedEvents,
      enrolledUsers,
      usersCourseCompleted,
      registrationStatitics,
      registraionCountryStatitics,
    },
  } = useLoaderData();

  return (
    <Grid container spacing={3} mt={3}>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <AppBox mb={1.5}>
          <ComplexStatisticsCard
            color="dark"
            icon="group"
            title="Enrolled Users"
            count={enrolledUsers}
          />
        </AppBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <AppBox mb={1.5}>
          <ComplexStatisticsCard
            icon="event"
            title="Total Events"
            count={conductedEvents}
          />
        </AppBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <AppBox mb={1.5}>
          <ComplexStatisticsCard
            color="success"
            icon="library_add"
            title="Available Courses"
            count={availableCourses}
          />
        </AppBox>
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <AppBox mb={1.5}>
          <ComplexStatisticsCard
            color="primary"
            icon="workspace_premium"
            title="Course Completed Users"
            count={usersCourseCompleted}
          />
        </AppBox>
      </Grid>
      {registrationStatitics.length > 0 &&
        registraionCountryStatitics.length > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {registrationStatitics && (
                <Grid item xs={12} md={12} lg={8} xl={8}>
                  <RegistrationStatiticsChart />
                </Grid>
              )}
              {registraionCountryStatitics && (
                <Grid item xs={12} md={12} lg={4} xl={4}>
                  <CountryStatiticsChart />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}

export default Dashboard;
