import { Formik, Form } from "formik";

// importing @core components
// import AppCard from "components/core/AppCard";
import AppBox from "components/core/AppBox";
import AppTypography from "components/core/AppTypography";

function AppFormCard({
  cardTitle,
  initialValues,
  validationSchema,
  onSubmit,
  children,
}) {
  return (
    <AppBox variant="contained" bgColor="white" p={3} borderRadius="lg">
      {cardTitle && (
        <AppBox display="flex" my={1}>
          <AppTypography variant="h6" color="dark">
            {cardTitle}
          </AppTypography>
        </AppBox>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => <Form onSubmit={handleSubmit}>{children}</Form>}
      </Formik>
    </AppBox>
  );
}

export default AppFormCard;
