import { useEffect } from "react";

// react router dom components
import { NavLink, useLocation } from "react-router-dom";
import { useCurrentLocation } from "hooks/useCurrentLocation";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Icon, Divider, List } from "@mui/material";

// importing @core components
import { AppBox, AppTypography } from "components/core";

// importing @app context and module fns
import { useKstAppController, setMiniSidenav } from "context/app";

// importing sideNav components
import SideNavRoot from "./SideNavRoot";
import SidenavCollapse from "./SideNavCollapse";
import sidenavLogoLabel from "./styles/sidenav";

// importing nav menu data
import { navMenus } from "./data";

function SideNav({ color, brand, brandName, ...rest }) {
  const [controller, dispatch] = useKstAppController();
  const { miniSideNav } = controller;

  const location = useLocation();
  const currentPath = useCurrentLocation(location);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1398);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const setActivemenu = (routeName) => {
    let isActive = false;

    if (routeName === "dashboard" && currentPath.length === 1) {
      isActive = true;
    } else if (currentPath.includes(routeName) && routeName !== "dashboard") {
      isActive = true;
    }

    return isActive;
  };

  const renderRoutes = () => (
    <>
      {navMenus.map(({ title, icon, routeName, path }) => (
        <NavLink to={path} key={title.toLowerCase()}>
          <SidenavCollapse
            name={title}
            icon={icon}
            active={setActivemenu(routeName)}
          />
        </NavLink>
      ))}
    </>
  );

  return (
    <SideNavRoot {...rest} ownerState={{ miniSideNav }} variant="permanent">
      <AppBox pt={3} pb={1} px={2} textAlign="center">
        <AppBox
          display={{ xs: "block", xxl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={() => setMiniSidenav(dispatch, true)}
          sx={{ cursor: "pointer" }}
        >
          <AppTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </AppTypography>
        </AppBox>
        <AppBox
          component={NavLink}
          to="/"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {brand && (
            <AppBox component="img" src={brand} alt="Brand" width="5rem" />
          )}
          <AppBox
            width={brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme)}
          >
            <AppTypography
              component="h1"
              variant="h4"
              fontWeight="medium"
              textAlign="center"
            >
              {brandName}
            </AppTypography>
          </AppBox>
        </AppBox>
        <Divider light />
        <List>{renderRoutes()}</List>
      </AppBox>
    </SideNavRoot>
  );
}

SideNav.defaultProps = {
  color: "primary",
  brand: "",
  brandName: "KST",
};

SideNav.propTypes = {
  color: PropTypes.oneOf(["primary"]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
};

export default SideNav;
