// color gradient
import colors from "assets/theme/base/colors";

// Material React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const { gradients, text } = colors;
const bgColor = "info";

const cardHeader = {
  styleOverrides: {
    root: {
      color: text.main,
      background: linearGradient(
        gradients[bgColor].main,
        gradients[bgColor].state
      ),
      padding: `${pxToRem(24)} ${pxToRem(16)} `,
      margin: `${pxToRem(-40)} ${pxToRem(40)} 0`,
      borderRadius: `${pxToRem(12)}`,
      textAlign: "center",
    },
  },
};

export default cardHeader;
