import { useState, useEffect } from "react";

import useToken from "./useToken";

function useUser() {
  const [token] = useToken();

  const getPayloadFromToken = (currentToken) => {
    const encodedPayload = currentToken.split(".")[1];
    return JSON.parse(atob(encodedPayload));
  };

  const [user, setUser] = useState(() => {
    if (!token) return null;
    return getPayloadFromToken(token);
  });

  useEffect(() => {
    if (!token) {
      setUser(null);
    } else {
      setUser(getPayloadFromToken(token));
    }
  }, [token]);

  return user;
}

export default useUser;
