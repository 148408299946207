import { useState, useEffect } from "react";

export const useCurrentLocation = (location) => {
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    const currentLoc = location.pathname
      .split("/")
      .filter((item) => item !== "");

    setCurrentPath(currentLoc);
  }, [location]);

  return currentPath;
};
