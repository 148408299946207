export const navMenus = [
  {
    title: "Dashboard",
    icon: "dashboard",
    routeName: "dashboard",
    path: "/dashboard",
  },
  {
    title: "Courses",
    icon: "library_add",
    routeName: "courses",
    path: "/dashboard/courses",
  },
  {
    title: "Modules",
    icon: "library_books",
    routeName: "modules",
    path: "/dashboard/modules",
  },
  {
    title: "Events",
    icon: "event",
    routeName: "events",
    path: "/dashboard/events",
  },
  {
    title: "Advertisements",
    icon: "ads_click",
    routeName: "advertisements",
    path: "/dashboard/advertisements",
  },
  {
    title: "Enrolled Users",
    icon: "group",
    routeName: "enrolled-users",
    path: "/dashboard/enrolled-users",
  },
  {
    title: "User Feedback",
    icon: "forum",
    routeName: "user-feedbacks",
    path: "/dashboard/user-feedbacks",
  },
  {
    title: "Contact Requests",
    icon: "contacts_product",
    routeName: "contact-requests",
    path: "/dashboard/contact-requests",
  },
  {
    title: "Settings",
    icon: "settings",
    routeName: "settings",
    path: "/dashboard/settings",
  },
];
