import axios from "axios";

const axiosConfig = {
  setAxiosBaseUrl: () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  },
  setAxiosAuthorizationHeader: (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common["kst-admin"] = 1;
  },
};

export default axiosConfig;
