import PropTypes from "prop-types";
import { ScrollRestoration } from "react-router-dom";

// importing Route change Nprogress hook
import useRouteProgress from "hooks/useRouteProgress";

// Material Dashboard React components
import { AppBox } from "components/core";

// importing dashboard layout components
import SideNav from "includes/SideNav";
import TopNavBar from "includes/TopNavBar";
import Footer from "includes/Footer";

// importing dashboard context provider
import { KstAppControllerProvider, useKstAppController } from "context/app";

function DashboardLayoutContainer({ children }) {
  const [controller] = useKstAppController();
  const { miniSideNav } = controller;

  return (
    <AppBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",
        minHeight: "100vh",
        [breakpoints.up("xxl")]: {
          marginLeft: miniSideNav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <TopNavBar />
      <AppBox py={3}>{children}</AppBox>
      <Footer />
      <ScrollRestoration />
    </AppBox>
  );
}

DashboardLayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function DashboardLayout({ children }) {
  useRouteProgress();

  return (
    <KstAppControllerProvider>
      <SideNav color="primary" />
      <DashboardLayoutContainer>{children}</DashboardLayoutContainer>
    </KstAppControllerProvider>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
