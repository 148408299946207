import { useEffect, useState } from "react";
import { keyframes } from "@emotion/react";
import { Link, useRouteError, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import { AppBox, AppTypography, AppButton } from "components/core";

import { AxiosError } from "axios";

import useUser from "auth/useUser";

const statusCodeAnim = keyframes({
  "0%,100%": {
    textShadow:
      "0 0 5px #00FFC6, 0 0 15px #00FFC6, 0 0 50px #00FFC6, 0 0 50px #00FFC6, 0 0 2px #B9FFE8, 2px 2px 3px #12E29C",
    color: "#4BFFEF",
  },
  "50%": {
    textShadow:
      "0 0 3px #00B58D, 0 0 7px #00B58D, 0 0 25px #00B58D, 0 0 25px #00B58D, 0 0 2px #00B58D, 2px 2px 3px #006A60",
    color: "#63D3AE",
  },
});

function ErrorPage() {
  const [errorStatus, setErrorStatus] = useState("");
  const user = useUser();
  const navigate = useNavigate();

  const error = useRouteError();

  const handleErrorStatus = () => {
    if (error instanceof AxiosError) {
      const {
        response: { status },
      } = error;
      setErrorStatus(status);
      if (status === 403 && localStorage.getItem("token")) {
        localStorage.removeItem("token");
      }
    } else {
      setErrorStatus(404);
    }
  };

  useEffect(() => {
    if (user) {
      handleErrorStatus();
    } else {
      navigate("/");
    }
  }, [user]);

  return (
    <AppBox
      sx={{
        backgroundColor: "#1C2127",
        height: "100vh",
      }}
      display="flex"
      alignItems="center"
      textAlign="center"
    >
      <Grid container>
        <Grid item lg={12}>
          <AppBox>
            <AppTypography
              variant="h1"
              sx={{
                color: "#5BE0B3",
                textShadow: "0 0 5px #6EECC1",
                fontSize: "90px",
                animation: `${statusCodeAnim} 2s linear infinite`,
              }}
            >
              {errorStatus}
            </AppTypography>
          </AppBox>
          <AppBox mt={1} mb={1}>
            <AppTypography variant="p" component="p">
              {errorStatus === 403
                ? "You are not authorized to access this page"
                : "The page you are looking for was not found"}
            </AppTypography>
          </AppBox>
          <AppBox pt={2} pb={2}>
            <Link to={user ? "/dashboard" : "/"}>
              <AppButton variant="contained" size="large" color="warning">
                Back to {user ? "Dashboard" : "Login"}
              </AppButton>
            </Link>
          </AppBox>
        </Grid>
      </Grid>
    </AppBox>
  );
}

export default ErrorPage;
