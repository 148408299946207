const ADMIN_PREFIX = "/admin";
const COURSE_QUIZ_TYPE = "course-quiz";
const CHAPTER_QUIZ_TYPE = "chapter-quiz";

/** Application Request Common URI'S */
const DASHBOARD_URI_PREFIX = `${ADMIN_PREFIX}/dashboard`;
const COURSES_URI = `${ADMIN_PREFIX}/courses`;
const COURSE_URI = `${ADMIN_PREFIX}/course`;
const COURSE_MODULE_URI = `${COURSE_URI}/module`;
const COURSE_MODULE_CHAPTER_URI = `${COURSE_MODULE_URI}/chapter`;
const EVENTS_URI = `${ADMIN_PREFIX}/events`;
const ADVERTISEMENTS_URI = `${ADMIN_PREFIX}/advertisements`;
const USERS_URI = `${ADMIN_PREFIX}/users`;
const FEEDBACKS_URI = `${ADMIN_PREFIX}/feedbacks`;
const SETTINGS_URI = `${ADMIN_PREFIX}/settings`;
/**
 * Admin Authenticated
 * Api urls
 */
export const LOGIN = `${ADMIN_PREFIX}/login`;
export const LOGOUT = `${ADMIN_PREFIX}/logout`;
export const AUTHORIZE = `${ADMIN_PREFIX}/authorize`;

/**
 * Api request for getting active languages
 */
export const ACTIVE_LANGUAGES = `/common/languages`;

/**
 * Get Application Statitics API Request URL
 */
export const DASHBOARD_STATITICS = `${DASHBOARD_URI_PREFIX}/statitics`;

/**
 * Course Api request urls
 */
export const ALL_COURSE = COURSES_URI;
export const CREATE_COURSE = COURSES_URI;
export const UPDATE_COURSE = (id) => `${COURSES_URI}/${id}`;
export const PUBLISH_COURSE = (id) => `${COURSES_URI}/${id}/publish`;
export const GET_COURSE = (id) => `${COURSES_URI}/${id}`;
export const DELETE_COURSE = (id) => `${COURSES_URI}/${id}`;

/**
 * Course Modules Request urls
 */
export const GET_ALL_MODULES = (page) =>
  page ? `${COURSES_URI}/modules?page=${page}` : `${COURSES_URI}/modules`;
export const GET_MODULE_BY_ID = (courseId, moduleId) =>
  `${COURSE_URI}/${courseId}/modules/${moduleId}`;
export const CREATE_COURSE_MODULE = (courseId) =>
  `${COURSE_URI}/${courseId}/modules`;
export const UPDATE_COURSE_MODULE = (courseId, moduleId) =>
  `${COURSE_URI}/${courseId}/modules/${moduleId}`;
export const DELETE_COURSE_MODULE = (courseId, moduleId) =>
  `${COURSE_URI}/${courseId}/modules/${moduleId}`;

/**
 * Course Quiz Api Request urls
 */
export const GET_ALL_COURSE_QUIZ = (courseId, page) =>
  page
    ? `${COURSE_URI}/${courseId}/quiz/${COURSE_QUIZ_TYPE}?page=${page}`
    : `${COURSE_URI}/${courseId}/quiz/${COURSE_QUIZ_TYPE}`;
export const CREATE_COURSE_QUIZ = (courseId) =>
  `${COURSE_URI}/${courseId}/quiz/${COURSE_QUIZ_TYPE}`;
export const UPDATE_COURSE_QUIZ = (courseId, quizId) =>
  `${COURSE_URI}/${courseId}/quiz/${quizId}/${COURSE_QUIZ_TYPE}`;
export const DELETE_COURSE_QUIZ = ({ courseId, quizId }) =>
  `${COURSE_URI}/${+courseId}/quiz/${quizId}/${COURSE_QUIZ_TYPE}`;

/**
 * Course Module Api Request Urls
 */
export const GET_MODULE_ASSETS_BY_ID = (moduleId) =>
  `${COURSE_MODULE_URI}/${moduleId}/assets`;
export const CREATE_MODULE_LINK_ASSET = (moduleId) =>
  `${COURSE_MODULE_URI}/${moduleId}/assets/link`;
export const UPDATE_MODULE_LINK_ASSET = (moduleId, assetId) =>
  `${COURSE_MODULE_URI}/${moduleId}/assets/link/${assetId}`;
export const CREATE_MODULE_PDF_ASSSET = (moduleId) =>
  `${COURSE_MODULE_URI}/${moduleId}/assets/pdf`;
export const UNLINK_PDF = (moduleId, assetId) =>
  `${COURSE_MODULE_URI}/${moduleId}/assets/delete/pdf/${assetId}`;
export const UPDATE_MODULE_PDF_ASSET = (moduleId, assetId) =>
  `${COURSE_MODULE_URI}/${moduleId}/assets/pdf/${assetId}`;
export const DELETE_MODULE_ASSET = (moduleId, assetId) =>
  `${COURSE_MODULE_URI}/${moduleId}/assets/${assetId}`;

/**
 * Course Module Chapter Api Request Urls
 */
export const GET_MODULE_CHAPTERS_BY_ID = (moduleId, page) =>
  page
    ? `${COURSE_MODULE_URI}/${moduleId}/chapters?page=${page}`
    : `${COURSE_MODULE_URI}/${moduleId}/chapters`;

export const GET_CHAPTER_BY_ID = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapters/${chapterId}`;
export const UNLINK_CHAPTER_POSTER_IMAGE = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapters/${chapterId}/poster-image`;
export const CREATE_MODULE_CHAPTER = (moduleId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapters`;
export const UPDATE_MODULE_CHAPTER = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapters/${chapterId}/update`;
export const DELETE_MODULE_CHAPTER = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapters/${chapterId}`;

/**
 * Chapter quiz API Request urls
 */
export const GET_ALL_CHAPTER_QUIZ = (chapterId, page) =>
  page
    ? `${COURSE_MODULE_CHAPTER_URI}/${chapterId}/quiz/${CHAPTER_QUIZ_TYPE}?page=${page}`
    : `${COURSE_MODULE_CHAPTER_URI}/${chapterId}/quiz/${CHAPTER_QUIZ_TYPE}`;
export const CREATE_CHAPTER_QUIZ = (chapterId) =>
  `${COURSE_MODULE_CHAPTER_URI}/${chapterId}/quiz/${CHAPTER_QUIZ_TYPE}`;
export const UPDATE_CHAPTER_QUIZ = (chapterId, quizId) =>
  `${COURSE_MODULE_CHAPTER_URI}/${chapterId}/quiz/${quizId}/${CHAPTER_QUIZ_TYPE}`;
export const DELETE_CHAPTER_QUIZ = ({ chapterId, quizId }) =>
  `${COURSE_MODULE_CHAPTER_URI}/${+chapterId}/quiz/${quizId}/${CHAPTER_QUIZ_TYPE}`;

/**
 * Course Module Chapter Asset Api request Urls
 */
export const UPLOAD_CHAPTER_ASSET_VIDEO = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/upload-video`;
export const CONVERT_CHAPTER_VIDEO = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/convert-video`;
export const DELETE_CHAPTER_STREAM = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/delete-stream`;
export const UPLOAD_SUBTITLE = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/upload-subtitle`;
export const DELETE_SUBTITLE = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/delete-subtitle`;
export const CREATE_VIDEO_QUIZ = (moduleId, chapterId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/create-quiz`;
export const UPDATE_VIDEO_QUIZ = (moduleId, chapterId, quizId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/update-quiz/${quizId}`;
export const DELETE_VIDEO_QUIZ = (moduleId, chapterId, quizId) =>
  `${COURSE_MODULE_URI}/${moduleId}/chapter/${chapterId}/delete-quiz/${quizId}`;

/**
 * Events Api request urls
 */
export const GET_ALL_EVENTS = `${EVENTS_URI}`;
export const CREATE_NEW_EVENT = `${EVENTS_URI}`;
export const GET_EVENT_BY_ID = (eventId) => `${EVENTS_URI}/${eventId}`;
export const UPDATE_EVENT = (eventId) => `${EVENTS_URI}/${eventId}`;
export const DELETE_EVENT = (eventId) => `${EVENTS_URI}/${eventId}`;
export const PUBLISH_EVENT = (eventId) => `${EVENTS_URI}/${eventId}/publish`;
export const GET_EVENT_PARTICIPANT_HISTORY = (eventId, page) =>
  page
    ? `${EVENTS_URI}/${eventId}/participant-history?page=${page}`
    : `${EVENTS_URI}/${eventId}/participant-history`;
export const GET_EVENT_PARTICIPANT_DETAILS_REPORT = (eventId) =>
  `${EVENTS_URI}/${eventId}/participant-history-report`;

/**
 * Event Prize API Request urls
 */
export const GET_ALL_EVENT_PRIZE = (eventId, page) =>
  page
    ? `${EVENTS_URI}/${eventId}/prizes?page=${page}`
    : `${EVENTS_URI}/${eventId}/prizes`;
export const CREATE_EVENT_PRIZE = (eventId) => `${EVENTS_URI}/${eventId}/prize`;
export const UPDATE_EVENT_PRIZE = (prizeId) =>
  `${EVENTS_URI}/prize/update/${prizeId}`;
export const UNLINK_EVENT_PRIZE = (prizeId) =>
  `${EVENTS_URI}/${prizeId}/prize/unlink-image`;
export const DELETE_EVENT_PRIZE = (prizeId) => `${EVENTS_URI}/prize/${prizeId}`;

/**
 * Advertisements Api Request urls
 */
export const GET_ALL_ADVERTISEMENTS = (page) =>
  page ? `${ADVERTISEMENTS_URI}?page=${page}` : ADVERTISEMENTS_URI;
export const GET_ADVERTISEMENT_CLICK_HISTORY = (id) =>
  `${ADVERTISEMENTS_URI}/${id}/click-history`;
export const CREATE_ADVERTISEMENT = ADVERTISEMENTS_URI;
export const UPDATE_ADVERTISEMENT = (advertisementId) =>
  `${ADVERTISEMENTS_URI}/${advertisementId}`;
export const UNLINK_ADVERTISEMENT_FILE = (advertisementId) =>
  `${ADVERTISEMENTS_URI}/${advertisementId}/unlink-file`;
export const DELETE_ADVERTISEMENT = (advertisementId) =>
  `${ADVERTISEMENTS_URI}/${advertisementId}`;

/**
 * Enrolled users API request URL
 */
export const ENROLLED_USERS = (page) =>
  page
    ? `${USERS_URI}/enrolled-users?page=${page}`
    : `${USERS_URI}/enrolled-users`;

/**
 * User feedbacks API request urls
 */
export const GET_ALL_USERS_FEEDBACKS = (page) =>
  page ? `${FEEDBACKS_URI}?page=${page}` : FEEDBACKS_URI;
export const UPDATE_FEEDBACK = (feedbackId) => `${FEEDBACKS_URI}/${feedbackId}`;

/**
 * Settings Api Request URL's
 */
export const GET_SETTINGS = `${SETTINGS_URI}`;
export const PUBLISH_LANGUAGE = (languageId) =>
  `${SETTINGS_URI}/status/${languageId}/language-status`;
export const CONFIGURE_QUIZ = `${SETTINGS_URI}/update-quiz`;

/** Contact Request URl */
export const GET_CONTACT_REQUEST = `${DASHBOARD_URI_PREFIX}/contact-requests`;
