import { useState } from "react";
import { Document, Page } from "react-pdf";

import { Fab } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

import { AppBox, AppTypography } from "components/core";

import PageLoader from "./PageLoader";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

function PdfViewer({ fileName }) {
  const [totalPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleIncrementPage = () => {
    if (pageNumber === totalPages) {
      return;
    }
    setPageNumber((prevPage) => prevPage + 1);
  };

  const handleDecrementPage = () => {
    if (pageNumber <= 1) {
      return;
    }
    setPageNumber((prevPage) => prevPage - 1);
  };

  return (
    <>
      <Document
        className="asset__viewer"
        file={fileName}
        loading={<PageLoader />}
        onLoadSuccess={onDocumentLoadSuccess}
        style={{
          height: "100%",
        }}
      >
        <Page loading={<PageLoader />} pageNumber={pageNumber} />
      </Document>
      <AppBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={2}
      >
        {totalPages > 1 ? (
          <>
            <AppTypography variant="h6" color="dark">
              Page {pageNumber} of {totalPages}
            </AppTypography>
            <AppBox display="flex" gap={1}>
              <Fab
                color="secondary"
                sx={({ spacing }) => ({
                  width: spacing(2),
                  height: spacing(2),
                  padding: spacing(2.5),
                })}
                disabled={pageNumber === 1}
                onClick={() => handleDecrementPage()}
              >
                <ArrowBackIosNew fontSize="small" />
              </Fab>
              <Fab
                color="secondary"
                sx={({ spacing }) => ({
                  width: spacing(2),
                  height: spacing(2),
                  padding: spacing(2.5),
                })}
                disabled={totalPages === pageNumber}
                onClick={() => handleIncrementPage()}
              >
                <ArrowForwardIos fontSize="small" />
              </Fab>
            </AppBox>
          </>
        ) : null}
      </AppBox>
    </>
  );
}

export default PdfViewer;
