import { Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { arrayOf, bool, number, shape, string } from "prop-types";

function AppSelect({
  options,
  displayEmpty,
  displayEmptyLabel,
  error,
  errorMessage,
  ...rest
}) {
  return (
    <>
      <FormControl error={error} fullWidth>
        <Select
          {...rest}
          variant="standard"
          displayEmpty={displayEmpty}
          sx={{
            top: "10px",
          }}
        >
          {displayEmpty ? (
            <MenuItem value="">{displayEmptyLabel}</MenuItem>
          ) : null}
          {options.length > 0 &&
            options.map((item) => (
              <MenuItem key={item.id} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {errorMessage ? (
        <FormHelperText sx={{ marginTop: "1rem" }} error>
          {errorMessage}
        </FormHelperText>
      ) : null}
    </>
  );
}

AppSelect.defaultProps = {
  displayEmpty: false,
  error: false,
  errorMessage: "",
  displayEmptyLabel: "",
};

AppSelect.proTypes = {
  options: arrayOf(
    shape({
      id: number,
      value: string,
      label: string,
    })
  ).isRequired,
  displayEmpty: bool,
  error: bool,
  errorMessage: string,
  displayEmptyLabel: string,
};

export default AppSelect;
