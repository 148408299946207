import { Navigate, Outlet, useLocation } from "react-router-dom";

import DashboardLayout from "layout/DashboardLayout";
import useUser from "./useUser";

function PrivateOutlet() {
  const user = useUser();
  const location = useLocation();

  if (user) {
    return (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    );
  }

  return <Navigate to="/" state={{ from: location }} replace />;
}

export default PrivateOutlet;
