import { Tooltip, Fab } from "@mui/material";
import { func, node, string } from "prop-types";

function IconWrapper({
  tooltipTitle,
  placement,
  color,
  handleAction,
  children,
}) {
  return (
    <Tooltip title={tooltipTitle} placement={placement}>
      <Fab
        color={color}
        sx={({ spacing }) => ({
          width: spacing(2),
          height: spacing(2),
          padding: spacing(2.5),
        })}
        onClick={() => handleAction()}
      >
        {children}
      </Fab>
    </Tooltip>
  );
}

IconWrapper.defaultProps = {
  placement: "top",
  color: "primary",
};

IconWrapper.propTypes = {
  tooltipTitle: string.isRequired,
  placement: string,
  color: string,
  handleAction: func.isRequired,
  children: node.isRequired,
};

export default IconWrapper;
