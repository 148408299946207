// Material Dashboard Components
import AppBox from "components/core/AppBox";
import AppTypography from "components/core/AppTypography";

function Footer() {
  return (
    <AppBox
      sx={{
        position: "absolute",
        bottom: 0,
        py: 2,
      }}
    >
      <AppTypography variant="button" color="dark">
        &copy; {new Date().getFullYear()}, maintained by INVIS
      </AppTypography>
    </AppBox>
  );
}

export default Footer;
