// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

//  Material Dashboard React components
import AppBox from "components/core/AppBox";

// Custom Styles for Navbar Collapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "includes/SideNav/styles/sidenavCollapse";

function SidenavCollapse({ icon, name, active, ...rest }) {
  return (
    <ListItem component="li">
      <AppBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            sidenavColor: "primary",
          })
        }
      >
        <ListItemIcon sx={(theme) => collapseIconBox(theme)}>
          {typeof icon === "string" ? (
            <Icon sx={(theme) => collapseIcon(theme)}>{icon}</Icon>
          ) : (
            icon
          )}
        </ListItemIcon>

        <ListItemText
          primary={name}
          sx={(theme) =>
            collapseText(theme, {
              active,
            })
          }
        />
      </AppBox>
    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
