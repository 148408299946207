import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

// Material ui components
import { CircularProgress, TextField } from "@mui/material";

// import formik components
import { Formik, Form } from "formik";

// import loginValidationSchema from "schema"
import { loginValidationSchema } from "utils/schema";

import axios from "axios";

// Material Dashboard Components
import AppBox from "components/core/AppBox";
import AppButton from "components/core/AppButton";

// importng Request API Url
import { LOGIN } from "constants/apiRequest";

import useToken from "auth/useToken";

function LoginForm() {
  const navigate = useNavigate();
  const [, setToken] = useToken();

  const { enqueueSnackbar } = useSnackbar();

  // Submitting Login Request and setting token
  // in user browser local storage
  const handleLoginSubmit = async (values, actions) => {
    await axios
      .post(LOGIN, values)
      .then((response) => {
        const { message, data } = response.data;
        setToken(data.token);
        enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 2000,
        });
        navigate("/dashboard");
      })
      .catch(({ response }) => {
        actions.setSubmitting(false);
        enqueueSnackbar(response.data.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={loginValidationSchema}
      validateOnChange
      onSubmit={handleLoginSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <AppBox mb={2} mt={2} pt={1} pb={1}>
            <TextField
              type="email"
              label="Email"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              error={errors.email && touched.email}
              helperText={errors.email}
              fullWidth
              inputProps={{
                autoComplete: "off",
              }}
            />
          </AppBox>
          <AppBox mb={2} pt={1} pb={1}>
            <TextField
              type="password"
              label="Password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              error={errors.password && touched.password}
              helperText={errors.password}
              fullWidth
              inputProps={{
                autoComplete: "off",
              }}
            />
          </AppBox>
          <AppBox mt={4} mb={1}>
            <AppButton
              variant="gradient"
              sx={{
                backgroundColor: "#F5D77F !important",
                color: "#7C672C",
                "&:hover": {
                  backgroundColor: "#e8c55f",
                },
              }}
              type="submit"
              fullWidth
              circular
              disabled={isSubmitting}
            >
              Login &nbsp;
              {isSubmitting && <CircularProgress color="inherit" size={12} />}
            </AppButton>
          </AppBox>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
