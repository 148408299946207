// importing @mui components
import { CircularProgress } from "@mui/material";

// importing @app components
import { AppTypography, AppBox } from "components/core";

function PageLoader({ title }) {
  return (
    <AppBox
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      width="100%"
      height="100%"
      mt={4}
    >
      <CircularProgress />
      {title && (
        <AppTypography variant="h6" color="dark" textAlign="center" mt={3}>
          {title}
        </AppTypography>
      )}
    </AppBox>
  );
}

PageLoader.defaultProps = {
  title: "",
};

export default PageLoader;
