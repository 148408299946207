import { useCallback, useState } from "react";

function useToggle() {
  const [toggle, setToggle] = useState(false);

  const handleToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);

  return [toggle, handleToggle];
}

export default useToggle;
