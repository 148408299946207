import { useLoaderData } from "react-router";
import { Card } from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AppBox } from "components/core";

import { randomRgba } from "utils/common";

ChartJS.register(ArcElement, Tooltip, Legend);

function CountryStatiticsChart() {
  const {
    statitics: { registraionCountryStatitics },
  } = useLoaderData();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Registration country statitics",
      },
    },
  };

  const data = {
    labels: registraionCountryStatitics?.map((rc) => rc.country.name),
    datasets: [
      {
        label: "# of registration",
        data: registraionCountryStatitics?.map((rc) => rc.totalUsers),
        backgroundColor: registraionCountryStatitics?.map(() => randomRgba()),
        borderColor: registraionCountryStatitics?.map(() => randomRgba()),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Card>
      <AppBox px={2} py={3.5}>
        <Doughnut data={data} options={options} />
      </AppBox>
    </Card>
  );
}

export default CountryStatiticsChart;
