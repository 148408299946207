import { NavLink } from "react-router-dom";
import { Breadcrumbs, Paper, Icon, Typography } from "@mui/material";

import useBreadcrumbs from "use-react-router-breadcrumbs";

import AppTypography from "components/core/AppTypography";

function getBreadcrumbData(url) {
  const lastSegment = url.split("/").pop();
  const breadcrumbInfo = {};

  switch (lastSegment) {
    case "dashboard":
      Object.assign(breadcrumbInfo, {
        title: "Dashboard",
        icon: "dashboard",
      });
      break;
    case "courses":
      Object.assign(breadcrumbInfo, {
        title: "Course",
        icon: "library_add",
      });
      break;
    case "quiz":
      Object.assign(breadcrumbInfo, {
        title: "Quiz",
        icon: "quiz",
      });
      break;
    case "modules":
      Object.assign(breadcrumbInfo, {
        title: "Module",
        icon: "library_books",
      });
      break;
    case "chapters":
      Object.assign(breadcrumbInfo, {
        title: "Chapter",
        icon: "video_library",
      });
      break;
    case "events":
      Object.assign(breadcrumbInfo, {
        title: "Events",
        icon: "event",
      });
      break;
    default:
      break;
  }

  if (url.includes("assets")) {
    if (url.includes("chapters")) {
      Object.assign(breadcrumbInfo, {
        title: "Chapter Assets",
        icon: "web_asset",
      });
    } else if (url.includes("modules")) {
      Object.assign(breadcrumbInfo, {
        title: "Module Assets",
        icon: "web_asset",
      });
    }
  } else if (url.includes("events")) {
    if (url.includes("create")) {
      Object.assign(breadcrumbInfo, {
        title: "Create Event",
        icon: "add_circle",
      });
    } else if (url.includes("update")) {
      Object.assign(breadcrumbInfo, {
        title: "Update Event",
        icon: "edit",
      });
    } else if (url.includes("prizes")) {
      Object.assign(breadcrumbInfo, {
        title: "Prizes",
        icon: "payments",
      });
    } else if (url.includes("participant-history")) {
      Object.assign(breadcrumbInfo, {
        title: "Event Participants",
        icon: "group",
      });
    }
  }

  return {
    url,
    ...breadcrumbInfo,
  };
}

function BreadCrumb({ title }) {
  const breadcrumbs = useBreadcrumbs();

  const breadcrumbNavItems = breadcrumbs
    .filter(
      (item) =>
        item.key !== "/" && !Number.isInteger(+item.key.split("/").pop())
    )
    .map((item) => getBreadcrumbData(item.key));

  return (
    <Paper
      elevation={0}
      sx={() => ({
        mt: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      })}
    >
      <Breadcrumbs>
        {breadcrumbNavItems.map((item, index) => {
          const last = index === breadcrumbNavItems.length - 1;

          return last ? (
            <AppTypography
              color="dark"
              variant="caption"
              fontWeight="medium"
              display="flex"
              alignItems="center"
              sx={{
                fontSize: "15px",
              }}
              key={item.url}
            >
              <Icon sx={{ mr: 1 }}>{item.icon}</Icon>
              {item.title}
            </AppTypography>
          ) : (
            <NavLink
              to={item.url}
              key={item.url}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon
                sx={{
                  mr: 1,
                }}
              >
                {item.icon}
              </Icon>
              {item.title}
            </NavLink>
          );
        })}
      </Breadcrumbs>
      {title ? (
        <Typography
          variant="h6"
          component="h6"
          px={2}
          display={{ xs: "none", sm: "none", md: "block" }}
        >
          {title}
        </Typography>
      ) : null}
    </Paper>
  );
}

export default BreadCrumb;
