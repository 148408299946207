// import propTypes
import { bool, func, node, string } from "prop-types";

// @mui components
import { Backdrop, Modal, Fade, Card } from "@mui/material";
import { Close } from "@mui/icons-material";

// App Core Components
import AppBox from "../AppBox";
import AppTypography from "../AppTypography";

function AppModal({ open, handleClose, title, children }) {
  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card
          sx={({ breakpoints }) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            [breakpoints.down("xl")]: {
              width: "50%",
            },
            [breakpoints.down("lg")]: {
              width: "75%",
            },
            [breakpoints.down("sm")]: {
              width: "95%",
            },
          })}
          variant="outlined"
        >
          <AppBox
            sx={{ margin: "0 1.5rem", padding: "1rem 0", minHeight: "20vh" }}
          >
            <AppBox
              display="flex"
              alignItems="center"
              justifyContent={title ? "space-between" : "flex-end"}
            >
              {title ? (
                <AppTypography variant="h6" color="dark">
                  {title}
                </AppTypography>
              ) : null}
              <AppBox
                p={1}
                sx={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              >
                <Close fontSize="medium" />
              </AppBox>
            </AppBox>
            {children}
          </AppBox>
        </Card>
      </Fade>
    </Modal>
  );
}

AppModal.defaultProps = {
  title: "",
};

AppModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  title: string,
  children: node.isRequired,
};

export default AppModal;
