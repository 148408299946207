import { useLoaderData } from "react-router";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Card } from "@mui/material";
import { AppBox } from "components/core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function RegistrationStatiticsChart() {
  const {
    statitics: { registrationStatitics },
  } = useLoaderData();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "User Registration Statitics",
      },
    },
  };

  const labels = registrationStatitics?.map((st) => st.joined_date);
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Registration",
        data: registrationStatitics?.map((st) => st.totalUsers),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <Card>
      <AppBox p={2}>
        <Line options={options} data={data} />
      </AppBox>
    </Card>
  );
}

export default RegistrationStatiticsChart;
