import { node } from "prop-types";
import { Card, CardContent } from "@mui/material";

function AppCard({ children, ...rest }) {
  return (
    <Card
      sx={{
        backgroundColor: "transperant",
        boxShadow: "none",
        ...rest,
      }}
    >
      <CardContent
        sx={{
          paddingBottom: "0.8rem !important",
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
}

AppCard.propTypes = {
  children: node.isRequired,
};

export default AppCard;
