import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Auth user hook
import useUser from "auth/useUser";

// material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard Components
import AppBox from "components/core/AppBox";
import AppTypography from "components/core/AppTypography";

// Authentication Layout
import PageLayout from "layout/PageLayout";

// Background Image
import bgImage from "assets/images/bg-signin.jpg";

// Login shared components
import LoginForm from "./shared/LoginForm";

function Login() {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      console.log(user);
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <PageLayout type="background" backgroundImage={bgImage}>
      <AppBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <AppBox
                variant="gradient"
                sx={{
                  backgroundColor: "#F5D77F",
                }}
                borderRadius="lg"
                coloredShadow="info"
                mx={3}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <AppTypography
                  variant="h4"
                  fontWeight="medium"
                  sx={{
                    color: "#121212",
                  }}
                >
                  Sign in
                </AppTypography>
              </AppBox>
              <AppBox pt={3} pb={3} px={3}>
                <LoginForm />
              </AppBox>
            </Card>
          </Grid>
        </Grid>
      </AppBox>
    </PageLayout>
  );
}

export default Login;
