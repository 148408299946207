import React from "react";
import { createRoot } from "react-dom/client";
import { pdfjs } from "react-pdf";
import nProgress from "nprogress";

import axiosConfig from "config/axios";

// importing nprogress css
import "nprogress/nprogress.css";

// importng root app element
import App from "./App";

// configuring application API URL to axios
axiosConfig.setAxiosBaseUrl();

// configuring Pdf Worker src
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// configuring Nprogress
nProgress.configure({ showSpinner: false });

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// rendering app root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
