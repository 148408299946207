import { useState, useEffect } from "react";

export const useCurrentLocationName = (location) => {
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    if (location) {
      const locations = location.pathname.replace("/", "").split("/");
      if (locations.length > 1) {
        setLocationName(locations[1]);
      } else {
        setLocationName(locations[0]);
      }
    }
  }, [location]);

  return locationName.split("-").join(" ");
};
