import { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import { PageLoader } from "components/page";

// Private Route
import PrivateOutlet from "auth/PrivateOutlet";

// App Login Page
import Login from "pages/login";

// App Dashboard Pages
import Dashboard from "pages/admin/dashboard";

import ErrorPage from "pages/error";

// importing Route Loaders
import {
  dashboardLoader,
  dashboardStaticsLoader,
  courseLoader,
  courseQuizLoader,
  moduleLoader,
  moduleAssetLoader,
  moduleChapterLoader,
  moduleChapterQuizLoader,
  moduleChapterAssetLoader,
  eventListLoader,
  createEventLoader,
  updateEventLoader,
  eventPrizeLoader,
  eventParticipantHistoryLoader,
  advertisementLoader,
  enrolledUsersLoader,
  usersFeedbackLoader,
  contactRequestLoader,
} from "./loaders";

const Course = lazy(() => import("pages/admin/course"));

const Module = lazy(() => import("pages/admin/module"));
const ModuleAssets = lazy(() => import("pages/admin/module/ModuleAsset"));
const Chapter = lazy(() => import("pages/admin/module/Chapter"));
const ChapterAsset = lazy(() => import("pages/admin/module/ChapterAsset"));

const Quiz = lazy(() => import("pages/admin/quiz"));

const Event = lazy(() => import("pages/admin/event"));
const CreateUpdateEvent = lazy(() =>
  import("pages/admin/event/CreateUpdateEvent")
);
const Prize = lazy(() => import("pages/admin/event/Prize"));

const ParticipantHistory = lazy(() =>
  import("pages/admin/event/ParticipantHistory")
);
const UserFeedBack = lazy(() => import("pages/admin/userFeedback"));
const EnrolledUsers = lazy(() => import("pages/admin/enrolledUsers"));
const ContactRequest = lazy(() => import("pages/admin/contactRequest"));

const Advertisement = lazy(() => import("pages/admin/advertisement"));
const Settings = lazy(() => import("pages/admin/settings"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorPage />}>
      <Route index element={<Login />} />
      <Route
        path="dashboard"
        loader={dashboardLoader}
        id="root"
        element={<PrivateOutlet />}
      >
        <Route index loader={dashboardStaticsLoader} element={<Dashboard />} />
        <Route path="courses">
          <Route
            index
            element={
              <Suspense fallback={<PageLoader />}>
                <Course />
              </Suspense>
            }
            loader={courseLoader}
          />
          <Route
            path=":courseId/quiz/:quizPage?"
            loader={courseQuizLoader}
            element={
              <Suspense fallback={<PageLoader />}>
                <Quiz />
              </Suspense>
            }
          />
        </Route>
        <Route path="modules/:page?">
          <Route
            index
            element={
              <Suspense fallback={<PageLoader />}>
                <Module />
              </Suspense>
            }
            loader={moduleLoader}
          />
          <Route
            path=":moduleId/assets"
            loader={moduleAssetLoader}
            element={
              <Suspense fallback={<PageLoader />}>
                <ModuleAssets />
              </Suspense>
            }
          />
          <Route path=":moduleId/chapters/:chapterPage?">
            <Route
              index
              element={
                <Suspense fallback={<PageLoader />}>
                  <Chapter />
                </Suspense>
              }
              loader={moduleChapterLoader}
            />
            <Route path=":chapterId">
              <Route
                path="assets"
                element={
                  <Suspense fallback={<PageLoader />}>
                    <ChapterAsset />
                  </Suspense>
                }
                loader={moduleChapterAssetLoader}
              />
              <Route
                path="quiz/:quizPage?"
                element={
                  <Suspense fallback={<PageLoader />}>
                    <Quiz />
                  </Suspense>
                }
                loader={moduleChapterQuizLoader}
              />
            </Route>
          </Route>
        </Route>
        <Route path="events">
          <Route
            index
            element={
              <Suspense fallback={<PageLoader />}>
                <Event />
              </Suspense>
            }
            loader={eventListLoader}
          />
          <Route
            path="create"
            element={
              <Suspense fallback={<PageLoader />}>
                <CreateUpdateEvent />
              </Suspense>
            }
            loader={createEventLoader}
          />
          <Route
            path=":eventId/update"
            element={
              <Suspense fallback={<PageLoader />}>
                <CreateUpdateEvent />
              </Suspense>
            }
            loader={updateEventLoader}
          />
          <Route
            path=":eventId/prizes/:page?"
            element={
              <Suspense fallback={<PageLoader />}>
                <Prize />
              </Suspense>
            }
            loader={eventPrizeLoader}
          />
          <Route
            path=":eventId/participant-history/:page?"
            element={
              <Suspense fallback={<PageLoader />}>
                <ParticipantHistory />
              </Suspense>
            }
            loader={eventParticipantHistoryLoader}
          />
        </Route>
        <Route
          path="advertisements/:page?"
          element={
            <Suspense fallback={<PageLoader />}>
              <Advertisement />
            </Suspense>
          }
          loader={advertisementLoader}
        />
        <Route path="enrolled-users/:page?">
          <Route
            index
            element={
              <Suspense fallback={<PageLoader />}>
                <EnrolledUsers />
              </Suspense>
            }
            loader={enrolledUsersLoader}
          />
        </Route>
        <Route path="user-feedbacks/:page?">
          <Route
            index
            element={
              <Suspense fallback={<PageLoader />}>
                <UserFeedBack />
              </Suspense>
            }
            loader={usersFeedbackLoader}
          />
        </Route>
        <Route
          path="contact-requests"
          loader={contactRequestLoader}
          element={
            <Suspense fallback={<PageLoader />}>
              <ContactRequest />
            </Suspense>
          }
        />
        <Route
          path="settings"
          element={
            <Suspense fallback={<PageLoader />}>
              <Settings />
            </Suspense>
          }
        />
      </Route>
    </Route>
  )
);

export default router;
