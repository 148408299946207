import { useNavigation } from "react-router";
import { useEffect } from "react";

import nProgress from "nprogress";

const useRouteProgress = () => {
  const { state } = useNavigation();

  useEffect(() => {
    if (state === "loading") nProgress.start();
    else if (state === "idle") nProgress.done();
  }, [state]);
};

export default useRouteProgress;
