import { CardMedia } from "@mui/material";
import { Delete } from "@mui/icons-material";

import { AppBox } from "components/core";

import IconWrapper from "./IconWrapper";

export default function ImagePreview({
  imageUrl,
  title,
  unlinkAction,
  height,
}) {
  return (
    <CardMedia
      sx={{
        height: height || 200,
        position: "relative",
        margin: 0,
      }}
      title={title}
      image={imageUrl}
    >
      <AppBox
        display="flex"
        position="absolute"
        top="-10px"
        bottom="0"
        right="-10px"
      >
        <IconWrapper
          tooltipTitle={`Delete ${title}`}
          placement="left"
          color="warning"
          handleAction={unlinkAction}
        >
          <Delete fontSize="medium" color="white" />
        </IconWrapper>
      </AppBox>
    </CardMedia>
  );
}
