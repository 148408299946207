// importing prop-types library
import PropTypes from "prop-types";

// importing @mui components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import AppButton from "../AppButton";

function AppDialogue({
  open,
  dialogueTitle,
  dialogueDescription,
  confirmBtnTitle,
  confirmBtnAction,
  handleClose,
}) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{dialogueTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogueDescription}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <AppButton
          onClick={confirmBtnAction}
          autoFocus
          variant="contained"
          color="info"
        >
          {confirmBtnTitle}
        </AppButton>
        <AppButton onClick={handleClose} variant="contained" color="primary">
          Cancel
        </AppButton>
      </DialogActions>
    </Dialog>
  );
}

AppDialogue.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogueTitle: PropTypes.string.isRequired,
  dialogueDescription: PropTypes.string.isRequired,
  confirmBtnTitle: PropTypes.string.isRequired,
  confirmBtnAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AppDialogue;
