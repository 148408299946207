export default function sidenavLogoLabel(theme) {
  const { functions, transitions, typography, breakpoints } = theme;

  const { pxToRem } = functions;
  const { fontSizeLG } = typography;

  return {
    ml: 0.5,
    fontWeight: fontSizeLG,
    wordSpacing: pxToRem(-1),
    transition: transitions.create("opacity", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up("xl")]: {
      opacity: 1,
    },
  };
}
